import { Injectable, Optional } from '@angular/core';
import { SortedFilteredTableDataManipulationService } from '@tremaze/shared/sorted-filtered-paginated-table/services';
import {
  EventNotificationUnit,
  EventScope,
  EventStatus,
  EventTemplate,
  isCanceledEventStatus,
  TemporalRegistrationRestriction,
  TemporalRegistrationRestrictionGroup,
  TremazeEvent,
} from '@tremaze/shared/feature/event/types';
import { SortedFilteredTableDataManipulationServiceResponse } from '@tremaze/shared/sorted-filtered-paginated-table/types';
import { filter, Observable, of, take, zip } from 'rxjs';
import { Router } from '@angular/router';
import {
  EventTemplateResultType,
  EventTemplateSelectService,
} from '@tremaze/shared/feature/event/template/feature/select';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ConfirmationService } from '@tremaze/shared/feature/confirmation';
import { NotificationService } from '@tremaze/shared/notification';
import { EventStateService } from '@tremaze/shared/feature/event';
import { EventCRUDDataSource } from '@tremaze/shared/feature/event/data-access';
import { EventScopeSelectorService } from '@tremaze/shared/feature/event/feature/event-scope-selector';
import { TremazeDate } from '@tremaze/shared/util-date';
import { TremazeEventPrivilegeChecks } from '@tremaze/shared/feature/event/util/privilege-checks';
import { ReferencePersonService } from '@tremaze/shared/feature/reference-person';
import { AuthV2Service } from '@tremaze/shared/core/auth-v2';
import { PermissionCheckService } from '@tremaze/shared/permission/services';
import {
  EventPreset,
  EventPresetSelectionService,
} from '@tremaze/shared/feature/event/feature/event-preset-selection';
import { TenantConfigService } from '@tremaze/shared/tenant-config';
import { SelectUserNotificationService } from '@tremaze/select-user-notification';
import {
  catchErrorMapTo,
  filterNotNullOrUndefined,
  filterTrue,
} from '@tremaze/shared/util/rxjs';
import { CustomForm } from '@tremaze/shared/feature/custom-forms/types';
import { HttpClient } from '@angular/common/http';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Address } from '@tremaze/shared/models';
import { OmitTypesafe } from '@tremaze/shared/util/types';
import {
  EventScheduleEditModel,
  ModifiableEventSchedule,
  TremazeSchedule,
} from '@tremaze/shared/scheduling/types';
import { User } from '@tremaze/shared/feature/user/types';
import { RemoteUserDataSource } from '@tremaze/shared/feature/user/data-access';
import { EventCancellationSettingServiceService } from '@tremaze/event-cancellation-reason';

type FC<T> = FormControl<T | null>;

type EditModel<T> = {
  [key in keyof T]: FC<T[key]>;
};

type ModifiableAddress = Pick<Address, 'street' | 'city' | 'zip' | 'addition'>;

export type AddressEditModel = EditModel<ModifiableAddress>;

interface ModifiableEventNotification {
  value: number;
  unit: EventNotificationUnit;
}

export type EventNotificationEditModel = EditModel<ModifiableEventNotification>;

type ModifiableTemporalRegistrationRestriction = Omit<
  TemporalRegistrationRestriction,
  'id' | 'meta'
>;

export type TemporalRegistrationRestrictionEditModel =
  EditModel<ModifiableTemporalRegistrationRestriction>;

export type EventTemporalRegistrationRestrictionGroupEditModel = {
  -readonly [key in keyof TemporalRegistrationRestrictionGroup]?: FormGroup<TemporalRegistrationRestrictionEditModel>;
};

type ModifiableEvent = Pick<
  Required<TremazeEvent>,
  | 'name'
  | 'startDate'
  | 'endDate'
  | 'categories'
  | 'description'
  | 'address'
  | 'institutions'
  | 'departments'
  | 'userTypes'
  | 'notifications'
  | 'contextInstitution'
  | 'isPublic'
  | 'gender'
  | 'maxAge'
  | 'minAge'
  | 'maxMember'
  | 'titleImage'
  | 'registrationNecessary'
  | 'visibleForFamily'
  | 'hideWhenFull'
  | 'highlight'
  | 'schedule'
  | 'specializations'
  | 'temporalRegistrationRestriction'
  | 'documentationForms'
  | 'isAllDay'
> & {
  hasVideoMeeting: boolean;
  publishImmediately: boolean;
};

export type EventEditModel = EditModel<
  OmitTypesafe<
    ModifiableEvent,
    'schedule' | 'address' | 'notifications' | 'temporalRegistrationRestriction'
  >
> & {
  address: FormGroup<AddressEditModel>;
  notifications: FormArray<FormGroup<EventNotificationEditModel>>;
  schedule?: FormGroup<EventScheduleEditModel>;
  temporalRegistrationRestriction: FormGroup<EventTemporalRegistrationRestrictionGroupEditModel>;
  employees: FormControl<User[]>;
  clients: FormControl<User[]>;
};

export type EventEditFormGroup = FormGroup<EventEditModel>;

export type EventEditFormResult = Omit<
  ModifiableEvent,
  'address' | 'schedule' | 'notifications' | 'temporalRegistrationRestriction'
> & {
  schedule?: ModifiableEventSchedule;
  address: ModifiableAddress;
  notifications: ModifiableEventNotification[];
  temporalRegistrationRestriction: TemporalRegistrationRestrictionGroup;
  clients: User[];
  employees: User[];
};

export function eventToPartialEventEditFormResult(
  event: TremazeEvent,
): Partial<EventEditFormResult> {
  return {
    ...event,
    address: event.address,
    notifications: event.notifications ?? [],
    schedule: event.schedule as any,
    hasVideoMeeting: !!event.videoMeeting,
    isAllDay: event.isAllDay,
    temporalRegistrationRestriction:
      event.temporalRegistrationRestriction ?? {},
  };
}

export interface EventEditParams {
  eventId?: string;
  isEdit: boolean;
  templateId?: string;
  userIds?: string[];
  institutionIds?: string[];
  departmentIds?: string[];
  date?: TremazeDate;
  dateTime?: TremazeDate;
  allDay?: boolean;
  preset?: EventPreset;
  dontAssignAuthUser?: boolean;
}

export abstract class EventEditService extends SortedFilteredTableDataManipulationService<TremazeEvent> {
  abstract override createItem(
    config?: Omit<EventEditParams, 'isEdit'>,
  ): Observable<
    | null
    | undefined
    | SortedFilteredTableDataManipulationServiceResponse<TremazeEvent>
  >;

  abstract updateDates(
    event: TremazeEvent,
    newStartDate: TremazeDate,
    newEndDate: TremazeDate,
    allDay: boolean,
    userUpdate?: { oldUserId: string; newUserId: string },
    updateNotifierId?: string,
  ): Observable<{ id: string; editScope: EventScope } | null>;

  abstract replanEvent(event: TremazeEvent): void;

  abstract override deleteItem(
    ev: TremazeEvent,
  ): Observable<boolean | undefined | null>;

  abstract switchPublished(
    ev: TremazeEvent,
  ): Observable<boolean | null | undefined>;

  abstract override editItem(
    item: TremazeEvent,
  ): Observable<SortedFilteredTableDataManipulationServiceResponse<TremazeEvent> | null>;

  abstract joinVideoMeeting(item: TremazeEvent): void;

  abstract notifyEventReload(updateId?: string): void;

  abstract editEventCancellationReason(eventId: string): Observable<void>;

  abstract updateEventStatus(
    id: string,
    status: EventStatus,
    remainingBudget?: number,
    config?: { skipReload: boolean },
  ): Observable<boolean>;
}

interface EventInput
  extends Partial<
    Pick<
      TremazeEvent,
      | 'address'
      | 'description'
      | 'highlight'
      | 'maxAge'
      | 'minAge'
      | 'maxMember'
      | 'prices'
      | 'registrationNecessary'
      | 'name'
      | 'visibleForFamily'
      | 'hideWhenFull'
      | 'temporalRegistrationRestriction'
    >
  > {
  genderIds?: string[];
  instIds?: string[];
  departmentIds?: string[];
  titleImageId?: string;
  userIds?: string[];
  categoryId?: string;
  public: boolean;
  userTypeIds?: string[];
  organizerId?: string;
  price?: number;
  startDate: string;
  endDate: string;
  notifications?: {
    value: number;
    unit: string;
  }[];
  specializationIds: string[];
  contextInstitutionId: string;
  schedule?: Pick<
    TremazeSchedule,
    | 'repeatNumber'
    | 'repeatDays'
    | 'startDate'
    | 'endDate'
    | 'repeatEvery'
    | 'allowedRegistrationScope'
  >;
  hasVideoMeeting: boolean;
  documentationCustomFormIds: string[];
  allDay: boolean;
}

function formResultToInputDTO(
  formResult: Partial<EventEditFormResult>,
): EventInput {
  const address = { ...(formResult.address ?? {}) } as any;

  const temporalRegistrationRestriction = {
    register: null,
    cancel: null,
  } as any;

  if (formResult.temporalRegistrationRestriction) {
    if (formResult.temporalRegistrationRestriction.register) {
      temporalRegistrationRestriction.register = {
        duration:
          formResult.temporalRegistrationRestriction.register.duration
            .inMinutes,
        relation: formResult.temporalRegistrationRestriction.register.relation,
      };
    }
    if (formResult.temporalRegistrationRestriction.cancel) {
      temporalRegistrationRestriction.cancel = {
        duration:
          formResult.temporalRegistrationRestriction.cancel.duration.inMinutes,
        relation: formResult.temporalRegistrationRestriction.cancel.relation,
      };
    }
  }

  delete address.country;

  const userIds = (formResult as any).users?.map((u: any) => u.id) ?? [];

  const payload = {
    address,
    categoryIds: formResult.categories?.map((c) => c.id) ?? [],
    contextInstitutionId: formResult.contextInstitution!.id,
    departmentIds: formResult.departments?.map((d) => d.id) ?? [],
    description: formResult.description,
    endDate: formResult.endDate!.toISOString(),
    genderIds: formResult.gender?.map((g) => g.id) ?? [],
    hideWhenFull: formResult.hideWhenFull,
    highlight: formResult.highlight,
    instIds: formResult.institutions?.map((i) => i.id) ?? [],
    maxAge: formResult.maxAge,
    maxMember: formResult.maxMember,
    minAge: formResult.minAge,
    name: formResult.name!,
    notifications: formResult.notifications,
    organizerId: undefined,
    price: undefined,
    prices: undefined,
    public: formResult.isPublic ?? false,
    registrationNecessary: formResult.registrationNecessary,
    specializationIds: formResult.specializations?.map((s) => s.id) ?? [],
    startDate: formResult.startDate!.toISOString(),
    temporalRegistrationRestriction,
    titleImageId: formResult.titleImage?.id,
    userIds: [
      ...(formResult.employees?.map((u) => u.id) ?? []),
      ...(formResult.clients?.map((u) => u.id) ?? []),
      ...userIds,
    ],
    userTypeIds: formResult.userTypes?.map((u) => u.id),
    visibleForFamily: formResult.visibleForFamily ?? false,
    hasVideoMeeting: formResult.hasVideoMeeting ?? false,
    schedule: undefined as any,
    documentationCustomFormIds:
      formResult.documentationForms?.map((f) => f.id) ?? [],
    allDay: formResult.isAllDay ?? false,
  };

  if (formResult.schedule) {
    const schedule = formResult.schedule;
    payload.schedule = {
      repeatNumber: schedule.repeatNumber,
      repeatDays: schedule.repeatDays,
      startDate: (schedule.startDate ?? formResult.startDate)?.toJSON(),
      endDate: schedule.endDate?.toJSON(),
      repeatEvery: schedule.repeatEvery,
      allowedRegistrationScope: schedule.allowedRegistrationScope,
    };
  }

  return payload;
}

@Injectable()
export class EventEditServiceDefaultImpl implements EventEditService {
  constructor(
    private readonly _router: Router,
    private readonly _confirmationService: ConfirmationService,
    private readonly _notificationService: NotificationService,
    private readonly _dataSource: EventCRUDDataSource,
    private readonly _eventScopeSelector: EventScopeSelectorService,
    private readonly _authService: AuthV2Service,
    private readonly _referencePersonService: ReferencePersonService,
    private readonly _permissionCheckService: PermissionCheckService,
    private readonly _eventPresetSelectionService: EventPresetSelectionService,
    private readonly _selectUserNotificationService: SelectUserNotificationService,
    private readonly _usersDataSource: RemoteUserDataSource,
    private readonly _http: HttpClient,
    private readonly _eventCancellationSettingServiceService: EventCancellationSettingServiceService,
    @Optional() private readonly _tenantConfigService?: TenantConfigService,
    @Optional() private eventStateService?: EventStateService,
    @Optional() private templateSelectService?: EventTemplateSelectService,
  ) {}

  private getEvent(id: string) {
    return zip(
      this._http.get(`events/${id}`),
      this._http
        .get(`events/${id}/forms/documentation`)
        .pipe(catchErrorMapTo([])),
    ).pipe(
      map(([event, documentation]) => {
        const documentationForms = (
          (documentation as Array<unknown>) ?? []
        ).map(CustomForm.deserialize);
        const data = {
          ...event,
          documentationForms,
        };
        return TremazeEvent.deserialize(data);
      }),
    );
  }

  private _updateDates(
    id: string,
    startDate: TremazeDate,
    endDate: TremazeDate,
    allDay: boolean,
    userUpdate?: { oldUserId: string; newUserId: string },
    scope?: EventScope,
  ): Observable<{ id: string }> {
    return this.getEvent(id).pipe(
      filterNotNullOrUndefined(),
      switchMap((event) => {
        event.startDate = startDate;
        event.endDate = endDate;
        event.isAllDay = allDay;

        if (userUpdate) {
          return this._usersDataSource.getFreshById(userUpdate.newUserId).pipe(
            switchMap((user) => {
              const users = event.users.filter(
                (u) => u.id !== userUpdate.oldUserId,
              );
              event.users = [...users, user];
              return this.update(id, eventToPartialEventEditFormResult(event), {
                scope,
              });
            }),
          );
        }

        return this.update(
          event.id!,
          eventToPartialEventEditFormResult(event),
          {
            scope,
          },
        );
      }),
    );
  }

  update(
    id: string,
    data: Partial<EventEditFormResult>,
    config: {
      scope?: EventScope;
    },
  ): Observable<{ id: string }> {
    const input = formResultToInputDTO(data);
    return this._http.put<{ id: string }>(`events/${id}`, input, {
      params: { eventScope: config.scope ?? 'SINGLE' },
    });
  }

  private _openEditComponent(cfg: EventEditParams) {
    const config = { ...cfg } as Partial<EventEditParams>;
    let queryParams: Record<string, unknown> = {};
    if (config.dateTime) {
      queryParams['startDateTime'] = config.dateTime.toISOString();
      delete config['dateTime'];
      delete config['date'];
    } else if (config.date) {
      queryParams['startDate'] = config.date.format('YYYY-MM-DD');
      delete config['date'];
    }
    const isEdit = config.isEdit;
    const eventId = config.eventId;
    if (isEdit) {
      delete config['eventId'];
    }
    delete config['isEdit'];

    queryParams = {
      ...queryParams,
      ...config,
    };

    // make all array values to string
    for (const [key, value] of Object.entries(queryParams)) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          queryParams[key] = value.join(',');
        } else {
          delete queryParams[key];
        }
      }
    }

    this._router.navigate(['event', isEdit ? eventId : 'create'], {
      queryParams,
    });
  }

  createItem(
    config?: Omit<EventEditParams, 'isEdit'>,
  ): Observable<
    | SortedFilteredTableDataManipulationServiceResponse<TremazeEvent>
    | null
    | undefined
  > {
    const editConfig: EventEditParams = {
      ...config,
      isEdit: false,
    };

    const skipPreset = !!config!.userIds?.length;

    const templateSelect$: Observable<EventTemplateResultType> =
      !config!.eventId && this.templateSelectService?.select
        ? this.templateSelectService?.select()
        : of('CONTINUE_WITHOUT');

    templateSelect$
      .pipe(
        filter((r) => r !== 'CANCEL'),
        map((template) => {
          if (template === 'CONTINUE_WITHOUT') {
            return null;
          }
          return (template as EventTemplate).id;
        }),
        switchMap((templateOrNull) => {
          return (
            this._tenantConfigService?.eventPresetsEnabled$ ?? of(true)
          ).pipe(
            switchMap((enabled) => {
              if (!enabled || skipPreset) {
                const noPreset: EventPreset = 'NONE';
                return of(noPreset);
              }
              return this._eventPresetSelectionService.selectPreset();
            }),
            map((preset) => ({ templateOrNull, preset })),
          );
        }),
        tap(({ templateOrNull, preset }) => {
          if (!preset) {
            return;
          }
          if (templateOrNull) {
            return this._openEditComponent({
              ...editConfig,
              templateId: templateOrNull ?? undefined,
              preset,
            });
          }
          return this._openEditComponent({ ...editConfig, preset });
        }),
      )
      .subscribe();

    return of(null);
  }

  updateDates(
    event: TremazeEvent,
    newStartDate: TremazeDate,
    newEndDate: TremazeDate,
    allDay: boolean,
    userUpdate?: { oldUserId: string; newUserId: string },
    updateNotifierId?: string,
  ): Observable<{ id: string; editScope: EventScope } | null> {
    if (event.isMultiDay) {
      this._notificationService
        .showNotification({
          message:
            'Mehrtägige Termine können nur über die Bearbeiten-Seite verschoben werden',
          actionName: 'Zur Bearbeiten-Seite',
        })
        .pipe(
          tap((r) => {
            if (r === 'ACTION') {
              this.editItem(event);
            }
          }),
        )
        .subscribe();
      return of(null);
    }
    return TremazeEventPrivilegeChecks.getEditPrivilegeRequest$(
      event,
      this._authService,
      this._referencePersonService,
    ).pipe(
      take(1),
      switchMap((request) => {
        return this._permissionCheckService.checkPermission$(request);
      }),
      switchMap((hasPermission) => {
        if (hasPermission) {
          return this.performAction<{
            id: string;
            editScope: EventScope;
          } | null>(event, (scope) => {
            return this._updateDates(
              event.id!,
              newStartDate,
              newEndDate,
              allDay,
              userUpdate,
              scope,
            ).pipe(
              tap((r) => {
                if (r) {
                  this.notifyEventReload(updateNotifierId);
                } else {
                  this._notificationService.showDefaultErrorNotification();
                }
              }),
              map((r) => ({ id: r.id, editScope: scope })),
            );
          });
        }
        this._notificationService.showNotification(
          'Du hast keine Berechtigung',
        );
        return of(null);
      }),
    );
  }

  replanEvent(event: TremazeEvent) {
    this._openEditComponent({
      isEdit: false,
      eventId: event.id,
    });
  }

  deleteItem(ev: TremazeEvent): Observable<boolean | null> {
    return (
      ev.hasDocumentation === true
        ? this._confirmationService
            .askUserForConfirmation({
              title: 'Dokumentieten Termin löschen',
              text: 'Der Termin verfügt über eine Dokumentation. Soll der Termin trotzdem gelöscht werden?',
              warn: true,
              confirmButtonText: 'Ja, löschen',
            })
            .pipe(map((r) => r?.confirmed === true))
        : of(true)
    ).pipe(
      filterTrue(),
      switchMap(() =>
        this._selectUserNotificationService.askUserForNotification(),
      ),
      filterNotNullOrUndefined(),
      switchMap((notifyUsers) =>
        this.performAction<boolean>(ev, (scope) => {
          return this._dataSource
            .deleteById(ev.id!, { scope }, notifyUsers)
            .pipe(
              tap((r) => {
                if (r) {
                  this._notificationService.showNotification(
                    `Die ${
                      scope === 'ALL' ? 'Serie' : 'Termin(e)'
                    } wurde gelöscht`,
                  );
                  this.notifyEventReload();
                } else {
                  this._notificationService.showDefaultErrorNotification();
                }
              }),
            );
        }),
      ),
    );
  }

  switchPublished(ev: TremazeEvent) {
    const notify$ =
      ev.published === true
        ? this._selectUserNotificationService.askUserForNotification()
        : of(true);
    return notify$.pipe(
      filterNotNullOrUndefined(),
      switchMap((notifyUsers) =>
        this.performAction<boolean>(
          ev,
          (scope) => {
            return this._dataSource
              .switchEventPublished(ev, { scope }, notifyUsers)
              .pipe(
                tap((r) => {
                  if (r) {
                    this._notificationService.showNotification(
                      ev.published
                        ? 'Die Veröffentlichung wurde zurückgezogen'
                        : 'Der Termin wurde veröffentlicht',
                    );
                    this.notifyEventReload();
                  } else {
                    this._notificationService.showDefaultErrorNotification();
                  }
                }),
              );
          },
          ['SINGLE', 'ALL'],
        ),
      ),
    );
  }

  editItem(
    item: TremazeEvent,
  ): Observable<SortedFilteredTableDataManipulationServiceResponse<TremazeEvent> | null> {
    this._router.navigate(['/event', item.id], {
      queryParams: { date: item.startDate!.format('YYYY-MM-DD') },
    });
    return of(null);
  }

  joinVideoMeeting(item: TremazeEvent) {
    this._router.navigate(['/event', item.id, `video-meeting`], {
      queryParams: {
        date: item.startDate!.format('YYYY-MM-DD'),
        subject: item.name,
      },
    });
  }

  notifyEventReload(updateId?: string) {
    if (this.eventStateService instanceof EventStateService) {
      this.eventStateService.triggerEventsReload(updateId);
    }
  }

  editEventCancellationReason(eventId: string) {
    return this._eventCancellationSettingServiceService.open(eventId).pipe(
      filter((r) => r?.changed),
      map(() => void 0),
    );
  }

  updateEventStatus(
    id: string,
    status: EventStatus,
    remainingBudget?: number,
    config?: { skipReload: boolean },
  ): Observable<boolean> {
    const req$ = this._dataSource.updateEventStatus(id, status).pipe(
      map((r) => true),
      tap(() => {
        if (!config?.skipReload) {
          this.notifyEventReload();
        }
      }),
    );

    if (isCanceledEventStatus(status)) {
      return this._eventCancellationSettingServiceService
        .open(id)
        .pipe(switchMap(() => req$));
    }

    if ((remainingBudget ?? 1) <= 0 && status === 'COMPLETED') {
      return this._confirmationService
        .askUserForConfirmation({
          title: 'Budget wird überschritten',
          text: 'Das Budget wird durch die Änderung des Status überschritten. Möchtest du fortfahren?',
          warn: true,
        })
        .pipe(
          switchMap((c) => {
            if (!c?.confirmed) {
              return of(false);
            }
            return req$;
          }),
        );
    }

    return req$;
  }

  private performAction<T>(
    ev: TremazeEvent,
    callback: (scope: EventScope) => Observable<T>,
    allowedScopes?: EventScope[],
  ): Observable<T | null> {
    const scope$ = this._eventScopeSelector.selectEventScope(ev, allowedScopes);
    return scope$.pipe(
      switchMap((scope) => {
        if (!scope) {
          throw new Error();
        }
        return this._confirmationService
          .askUserForConfirmation({ warn: true })
          .pipe(
            switchMap((conti) => {
              if (conti?.confirmed) {
                return callback(scope) as never;
              }
              throw null;
            }),
          );
      }),
      catchError(() => of(null)),
    );
  }
}

export const provideEventEditService = () => ({
  provide: EventEditService,
  useClass: EventEditServiceDefaultImpl,
});
