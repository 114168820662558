import { inject, Injectable } from '@angular/core';
import { FileSelectorComponent } from './file-selector.component';
import { Observable } from 'rxjs';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { FileSelectorService } from './file-selector.service';
import { FileSelectorConfig } from './config';

@Injectable()
export class FileSelectorServiceImpl implements FileSelectorService {
  private readonly _dialog = inject(MatDialog);

  selectFiles(config?: FileSelectorConfig): Observable<FileStorage[] | null> {
    return this._dialog
      .open(FileSelectorComponent, {
        panelClass: ['dialog-no-padding', 'fullscreen'],
        data: config,
      })
      .afterClosed()
      .pipe(map((r) => (r instanceof FileStorage ? [r] : null)));
  }
}
