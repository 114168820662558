import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DirStorage,
  FileStorage,
} from '@tremaze/shared/feature/file-storage/types';
import { FolderViewBreadcrumbsComponent } from '../folder-view-breadcrumbs/folder-view-breadcrumbs.component';
import { FolderViewFolderItemComponent } from '../folder-view-folder-item/folder-view-folder-item.component';
import { FolderViewFoldersComponent } from '../folder-view-folders/folder-view-folders.component';
import { FolderViewFilesComponent } from '../folder-view-files/folder-view-files.component';
import { FolderViewFileItemComponent } from '../folder-view-file-item/folder-view-file-item.component';
import { FolderViewDragPreviewComponent } from './folder-view-drag-preview/folder-view-drag-preview.component';
import animations from '../animations';
import { SelectZoneDirective, SelectZoneModule } from '@tremaze/select-zone';
import {
  DragZoneDirective,
  DragZoneModule,
  DragZonePreviewComponent,
} from '@tremaze/drag-zone';
import { Observable } from 'rxjs';
import { FileDropInDropZoneDirective } from '@tremaze/file-drop-in';
import { IconComponent } from '@tremaze/shared/ui/icon';
import { ExpandingIconTextInputComponent } from '@tremaze/shared/ui/inputs/expanding-icon-text-input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'tremaze-folder-view-folders-and-files',
  standalone: true,
  imports: [
    CommonModule,
    FolderViewBreadcrumbsComponent,
    FolderViewFolderItemComponent,
    FolderViewFoldersComponent,
    FolderViewFilesComponent,
    FolderViewFileItemComponent,
    FolderViewDragPreviewComponent,
    SelectZoneModule,
    DragZoneModule,
    FileDropInDropZoneDirective,
    IconComponent,
    ExpandingIconTextInputComponent,
    MatProgressSpinner,
  ],
  templateUrl: './folder-view-folders-and-files.component.html',
  styleUrl: './folder-view-folders-and-files.component.scss',
  animations: [animations.listAnimation],
  hostDirectives: [SelectZoneDirective, DragZoneDirective],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'folder-view-folders-and-files',
  },
})
export class FolderViewFoldersAndFilesComponent
  implements OnInit, AfterViewInit
{
  private _selectZone = inject(SelectZoneDirective, { self: true });
  private _dragZone = inject(DragZoneDirective, { self: true });

  @ViewChild(DragZonePreviewComponent)
  dragPreviewComponent?: DragZonePreviewComponent;

  @Input({ required: true }) folder?: DirStorage;
  @Input({ required: true }) folders?: DirStorage[];
  @Input({ required: true }) files?: FileStorage[];
  @Input() isScrolled = false;

  @Input() isLoadingFiles = false;

  @Input() selectedFolders?: DirStorage[];
  @Input() selectedFiles?: FileStorage[];

  @Input() showCreateFolder = false;
  @Input() selectionRegex?: RegExp | null;

  @Input() set disableSelectZone(value: boolean) {
    this._selectZone.disabled = value;
  }

  @Input() getCanWriteStreamForFileOrFolder?: (
    file: FileStorage | DirStorage,
  ) => Observable<boolean>;
  @Input() getCanDeleteStreamForFileOrFolder?: (
    file: FileStorage | DirStorage,
  ) => Observable<boolean>;

  @Input() searchValue: string | null = null;

  @Output() readonly navigate = new EventEmitter<string>();
  @Output() readonly clickedFile = new EventEmitter<FileStorage>();
  @Output() readonly clickedFolder = new EventEmitter<DirStorage>();
  @Output() readonly doubleClickedFolder = new EventEmitter<DirStorage>();
  @Output() readonly doubleClickedFile = new EventEmitter<FileStorage>();
  @Output() readonly submitFolderName = new EventEmitter<{
    name: string;
    folderId?: string;
  }>();
  @Output() readonly submitFileName = new EventEmitter<{
    name: string;
    fileId: string;
  }>();
  @Output() readonly cancelCreateFolder = new EventEmitter<void>();
  @Output() readonly showFilePreview = new EventEmitter<FileStorage>();
  @Output() readonly duplicateFile = new EventEmitter<FileStorage>();
  @Output() readonly deleteFile = new EventEmitter<FileStorage>();
  @Output() readonly downloadFile = new EventEmitter<FileStorage>();
  @Output() readonly copyFileLink = new EventEmitter<FileStorage>();
  @Output() readonly openFolder = new EventEmitter<DirStorage>();
  @Output() readonly deleteFolder = new EventEmitter<DirStorage>();
  @Output() readonly uploadFiles = new EventEmitter<{
    files: File[];
    path?: string;
  }>();
  @Output() readonly selectionStart = new EventEmitter<void>();
  @Output() readonly selectionChange = new EventEmitter<{
    files: FileStorage[];
    folders: DirStorage[];
  }>();
  @Output() readonly moveFilesAndFolders = new EventEmitter<{
    files: FileStorage[];
    folders: DirStorage[];
    targetPath: string;
  }>();
  @Output() readonly searchValueChange = new EventEmitter<string>();

  ngOnInit() {
    this._selectZone.selectionStart.subscribe(() => {
      this.selectionStart.emit();
    });
    this._selectZone.selectionChange.subscribe((i) => {
      const items = i as (FileStorage | DirStorage)[];
      const files: FileStorage[] = [];
      const folders: DirStorage[] = [];
      items.forEach((item) => {
        if (item instanceof FileStorage) {
          files.push(item);
        } else {
          folders.push(item);
        }
      });
      this.selectionChange.emit({ files, folders });
    });
  }

  ngAfterViewInit() {
    this._dragZone.dragPreviewTemplate = this.dragPreviewComponent;
  }

  @HostListener('click')
  onClick() {
    if (!this._dragZone.isDragging) {
      this.selectionStart.emit();
    }
  }

  isFileDisabled(file: FileStorage): boolean {
    if (this.selectionRegex) {
      return !this.selectionRegex.test(file.fileType);
    }
    return false;
  }

  isFolderSelected(folder: DirStorage): boolean {
    return this.selectedFolders?.some((f) => f.id === folder.id) ?? false;
  }

  isFileSelected(file: FileStorage) {
    return this.selectedFiles?.some((f) => f.id === file.id) ?? false;
  }

  onClickFolder(folder: DirStorage, event: Event) {
    event.stopPropagation();
    this.clickedFolder.emit(folder);
  }

  onClickBreadcrumb(path: string) {
    this.navigate.emit(path);
  }

  onClickFile(file: FileStorage, event: Event) {
    event.stopPropagation();
    this.clickedFile.emit(file);
  }

  onDoubleClickFolder(folder: DirStorage) {
    this.doubleClickedFolder.emit(folder);
  }

  onDoubleClickFile(file: FileStorage) {
    this.doubleClickedFile.emit(file);
  }

  onSubmitFolderName(name: string, folderId?: string) {
    this.submitFolderName.emit({ name, folderId });
  }

  onCancelCreateFolder() {
    this.cancelCreateFolder.emit();
  }

  onDataDropped(data: unknown[], path: string) {
    const d = data as (FileStorage | DirStorage)[];
    const files: FileStorage[] = [];
    const folders: DirStorage[] = [];
    d.forEach((item) => {
      if (item instanceof FileStorage) {
        files.push(item);
      } else {
        folders.push(item);
      }
    });
    this.moveFilesAndFolders.emit({ files, folders, targetPath: path });
  }

  onFilesDropped(files: File[], path?: string) {
    this.uploadFiles.emit({ files, path });
  }
}
