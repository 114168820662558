import { enableProdMode, ViewEncapsulation } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import packageInfo from '../../../package.json';
import { httpClientIntegration } from '@sentry/integrations';

import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense(
  'Ngo9BigBOggjHTQxAR8/V1NDaF5cWWtCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWH9ecXRWQ2dYVUR2WUs=',
);

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: 'https://c5c189972b60f20924f5b8d7da74d218@o492774.ingest.sentry.io/4505798800310272',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Integrations.Breadcrumbs(),
    httpClientIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: environment.production ? 0.5 : 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: environment.stage,
  release: 'tagea-control-center@' + packageInfo.version,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    defaultEncapsulation: ViewEncapsulation.Emulated,
  })
  .catch((err) => console.error(err));
