<div #scrollContainer>
  @if (service.currentDirectory$ | async; as currentFolder) {
    <tremaze-folder-view-folders-and-files
      [folder]="currentFolder"
      [folders]="(service.currentSubDirectories$ | async) ?? []"
      [files]="(service.currentFiles$ | async) ?? []"
      [selectedFiles]="(service.selectedFiles$ | async) ?? []"
      [selectedFolders]="(service.selectedDirectories$ | async) ?? []"
      [showCreateFolder]="isCreatingFolder"
      [disableSelectZone]="singleSelection"
      [selectionRegex]="service.selectionRegex$ | async"
      [getCanWriteStreamForFileOrFolder]="
        service.canWriteFileOrFolderFn$ | async
      "
      [getCanDeleteStreamForFileOrFolder]="
         service.canDeleteFileOrFolderFn$ | async
      "
      [searchValue]="service.searchValue$ | async"
      [isScrolled]="(isScrolled$ | async) ?? false"
      [isLoadingFiles]="(service.isLoadingFiles$ | async) ?? true"
      (navigate)="onNavigate($event)"
      (clickedFolder)="onClickDirectory($event)"
      (doubleClickedFolder)="onDoubleClickDirectory($event)"
      (clickedFile)="onClickFile($event)"
      (doubleClickedFile)="onDoubleClickFile($event)"
      (submitFolderName)="submitFolderName($event)"
      (submitFileName)="submitFileName($event)"
      (cancelCreateFolder)="cancelCreateFolder()"
      (showFilePreview)="service.openFile($event)"
      (duplicateFile)="service.copyFileToCurrentFolder($event)"
      (deleteFile)="service.deleteFile($event)"
      (copyFileLink)="service.copyFileLink($event)"
      (downloadFile)="service.downloadFile($event)"
      (openFolder)="service.openDirectory($event)"
      (deleteFolder)="service.deleteDirectory($event)"
      (selectionStart)="service.clearSelection()"
      (selectionChange)="service.setSelection($event.files, $event.folders)"
      (uploadFiles)="service.uploadFiles($event.files, $event.path)"
      (moveFilesAndFolders)="onMoveFilesAndFolders($event)"
      (searchValueChange)="onSearchValueChange($event)"
    ></tremaze-folder-view-folders-and-files>
  }
</div>
@if (service.currentDirectory$ | async; as currentFolder) {
  <tremaze-folder-view-actions-row
    [selectedFiles]="(service.selectedFiles$ | async) ?? []"
    [selectedFolders]="(service.selectedDirectories$ | async) ?? []"
    [hideSelectedFilesAndFoldersButton]="singleSelection"
    [showSelectFromFileStorageButton]="showSelectFromFileStorageButton"
    (addFolder)="createFolder()"
    (addFile)="fileInput.click()"
    (openSelected)="service.openSelected()"
    (selectFromFileStorage)="clickedSelectFromFileStorage.emit()"
    class="mat-elevation-z3"
  >
    <ng-container slot="start">
      <ng-content select="[slot=actions-start]"></ng-content>
    </ng-container>

    @if (singleSelection) {
      <button
        style="margin-right: 10px"
        mat-raised-button
        color="accent"
        [disabled]="service.hasNoSelection$ | async"
        (click)="onClickConfirmSelection()"
      >
        Auswahl bestätigen
      </button>
    }
  </tremaze-folder-view-actions-row>

  <input
    type="file"
    hidden
    #fileInput
    multiple
    (change)="onFilesSelected($event)"
  />
}
