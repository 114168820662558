import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { FileSelectorConfig } from './config';

@Component({
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.scss'],
})
export class FileSelectorComponent {
  constructor(
    private ref: MatDialogRef<FileSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) readonly data: FileSelectorConfig,
  ) {}

  onFileSelected(file: FileStorage) {
    this.ref.close(file);
  }

  onClose() {
    this.ref.close();
  }
}
