import { ChangeDetectorRef, Injectable } from '@angular/core';
import {
  CustomForm,
  CustomFormFeature,
  CustomFormFieldType,
} from '@tremaze/shared/feature/custom-forms/types';
import { CustomFormEditFormBuilderService } from '../custom-form-edit-form-builder.service';
import { moveInArray } from '@tremaze/shared/util-utilities';
import { CustomFormCRUDDataSource } from '@tremaze/shared/feature/custom-forms/data-access';
import {
  CustomFormsMultiSelectFieldType,
  FormGroupModel,
} from '../form-models';
import { FormGroup } from '@angular/forms';
import { NotificationService } from '@tremaze/shared/notification';
import {
  catchError,
  concatWith,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
  mergeMap,
  MonoTypeOperatorFunction,
  Observable,
  of,
  OperatorFunction,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import {
  ensureObservable,
  filterFalse,
  filterNotNullOrUndefined,
  filterTrue,
} from '@tremaze/shared/util/rxjs';
import { CustomFormEditValueObserverService } from '../custom-form-edit-value-observer.service';
import { InstitutionREADDataSource } from '@tremaze/shared/feature/institution/data-access';
import { Institution } from '@tremaze/shared/feature/institution/types';
import { CustomFormEditConfig } from '../config';

/**
 * Service to handle the logic of the custom form edit component.
 */
@Injectable()
export class CustomFormEditComponentService {
  readonly _destroy$ = new Subject<void>();
  protected readonly _triedSubmittingInvalid$ = new Subject<void>();
  protected readonly _submittedValid$ = new Subject<CustomForm>();
  readonly availableInstitutions$: Observable<Institution[]> =
    this._institutionDataSource
      .getPaginated({
        filter: {
          page: 0,
          pageSize: 100,
          sort: 'name',
          sortDirection: 'asc',
        },
      })
      .pipe(
        map((page) => page.content),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        }),
      );

  private get _wasFormTouched(): boolean {
    return (
      this.form.controls.fields.touched ||
      this.form.controls.name.touched ||
      this.form.controls.feature.touched ||
      this.form.controls.published.touched ||
      this.form.controls.institutions.touched
    );
  }

  get triedSubmittingInvalid$(): Observable<void> {
    return this._triedSubmittingInvalid$.asObservable();
  }

  readonly form: FormGroup<FormGroupModel> =
    this._formBuilderService.buildForm();
  readonly _submitEvent$ = new Subject<void>();
  readonly _submissionError$ = new Subject<void>();
  readonly loadingForm$ = new Subject<boolean>();
  /**
   * Emits a CustomForm every time the form is submitted successfully. Will only emit if the form is valid and
   * submit is called.
   */
  readonly result$: Observable<CustomForm> = this._createResultStream();
  readonly loading$: Observable<boolean> = this._createLoadingStream();

  constructor(
    private readonly _formBuilderService: CustomFormEditFormBuilderService,
    private readonly _valueObserverService: CustomFormEditValueObserverService,
    private readonly _dataSource: CustomFormCRUDDataSource,
    protected readonly _notificationService: NotificationService,
    private readonly _cdRef: ChangeDetectorRef,
    private readonly _institutionDataSource: InstitutionREADDataSource,
  ) {}

  get feature(): CustomFormFeature | undefined {
    return this._config?.feature;
  }

  get formDirty(): boolean {
    return this.form.dirty;
  }

  get formPristine(): boolean {
    return !this.formDirty;
  }

  readonly formPristine$: Observable<boolean> = this.form.valueChanges.pipe(
    map(() => this.formPristine),
    startWith(this.formPristine),
    distinctUntilChanged(),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  private _config: CustomFormEditConfig | undefined;

  get formId(): string | undefined {
    return this._config?.formId;
  }

  set config(value: CustomFormEditConfig | undefined) {
    if (value !== this._config) {
      this._config = value;
      this._prePopulateForm(value);
      return;
    } else if (!value) {
      this._prePopulateForm();
    }
  }

  get config(): CustomFormEditConfig | undefined {
    return this._config;
  }

  get isEdit(): boolean {
    return !!this.formId;
  }

  public get firstInvalidFieldIndex(): number | undefined {
    return this.form.controls.fields.controls.findIndex((v) => v.invalid);
  }

  private _createLoadingStream(): Observable<boolean> {
    return merge(
      this.loadingForm$,
      this._submitEvent$.pipe(map(() => true)),
      this.result$.pipe(map(() => false)),
      this.triedSubmittingInvalid$.pipe(map(() => false)),
      this._submissionError$.pipe(map(() => false)),
    ).pipe(
      startWith(false),
      takeUntil(this._destroy$),
      shareReplay({
        bufferSize: 1,
        refCount: true,
      }),
    );
  }

  /**
   * Creates the result stream. Handles submission, publishing, errors and notifications.
   * @private
   */
  private _createResultStream(): Observable<CustomForm> {
    return this._submitEvent$.pipe(
      // Extracting this to know if the form itself was touched
      map(() => this._wasFormTouched),
      tap(() => {
        this.form.markAllAsTouched();
        this.form.updateValueAndValidity();
      }),
      map((wasFormTouched) => ({
        valid: this.form.valid && this.form.controls.fields.length > 0,
        wasFormTouched,
      })),
      this._notifyIfInvalid(),
      // If the form is invalid, we don't want to continue
      filter(({ valid }) => valid),
      // Mapping form value to CustomForm
      map(({ wasFormTouched }) => ({
        form: extractFormGroupValueToCustomForm(
          this.form,
          this.formId,
          this.feature,
        ),
        wasFormTouched,
      })),
      tap(({ form }) => this._submittedValid$.next(form)),
      // The form will be disabled as long as the submission is in progress
      tap(() => this.form.disable()),
      // Prevents the form from being submitted multiple times
      debounceTime(1000),
      this._mapToResult(),
      // Updating the id since it changes after every edit
      tap((f: CustomForm) => {
        if (this._config) {
          this._config.formId = f.id;
        } else {
          this._config = { formId: f.id };
        }
      }),
      this._handleSubmissionError(),
      this._updatePublishedIfNecessary(),
      tap(() => {
        this.form.markAsPristine();
        this.form.enable();
      }),
      takeUntil(this._destroy$),
      shareReplay({
        bufferSize: 1,
        refCount: true,
      }),
    ) as Observable<CustomForm>;
  }

  /**
   * Notifies the user if the form is invalid. This is used in the result stream.
   * @private
   */
  private _notifyIfInvalid<
    T extends { valid: boolean; wasFormTouched: boolean },
  >(): MonoTypeOperatorFunction<T> {
    return (ob$: Observable<T>) =>
      ob$.pipe(
        tap<T>(({ valid }) => {
          if (!valid) {
            this._triedSubmittingInvalid$.next();
            if (this.form.controls.fields.length == 0) {
              this._notificationService.showNotification(
                'Bitte füge mindestens ein Feld hinzu',
              );
            } else {
              this._notificationService.showDefaultErrorNotification();
            }
          }
        }),
      );
  }

  /**
   * Maps the form value to a CustomForm. If the form itself has not been touched the form will not be submitted.
   * This is used in the result stream.
   * @private
   */
  private _mapToResult<
    I extends { form: CustomForm; wasFormTouched: boolean },
  >(): OperatorFunction<I, CustomForm> {
    return (ob$: Observable<I>) =>
      ob$.pipe(
        mergeMap(({ form, wasFormTouched }) => {
          if (!wasFormTouched) {
            return of(form);
          } else if (this.isEdit) {
            return this._dataSource.edit(form);
          }
          return this._dataSource.create(form);
        }),
      );
  }

  /**
   * Handles the submission error. This is used in the result stream.
   * @private
   */
  private _handleSubmissionError(): OperatorFunction<CustomForm, CustomForm> {
    return (ob$: Observable<CustomForm>) =>
      ob$.pipe(
        catchError((err) => {
          console.error(err);
          this._notificationService.showDefaultErrorNotification();
          this.form.enable();
          this._submissionError$.next();
          return of(null);
        }),
        filterNotNullOrUndefined(),
      );
  }

  /**
   * Updates the published state of the form if necessary. This is used in the result stream.
   * @private
   */
  private _updatePublishedIfNecessary(): OperatorFunction<
    CustomForm,
    CustomForm
  > {
    return (ob$: Observable<CustomForm>) =>
      ob$.pipe(
        mergeMap((customForm) => {
          if (!this.form.controls.published.dirty) {
            return of(customForm);
          }
          const published = this.form.controls.published.value ?? false;
          return this._dataSource.setPublished(customForm.id, published).pipe(
            map(() =>
              CustomForm.deserialize({
                ...customForm,
                published,
              }),
            ),
          );
        }),
      );
  }

  /**
   * Cleans up to prevent memory leaks. Should be called when the host component is destroyed.
   */
  public destroy(): void {
    this._valueObserverService.destroy();
    this._triedSubmittingInvalid$.complete();
    this._submittedValid$.complete();
    this._submitEvent$.complete();
    this._submissionError$.complete();
    this.loadingForm$.complete();
    this._destroy$.next();
    this._destroy$.complete();
  }

  /**
   * Adds a new field to the form.
   * @param type The type of the field to add
   */
  addNewField(type: CustomFormFieldType): void {
    const newGroup = this._formBuilderService.createFormFieldFormGroup(type);
    newGroup.controls.sort?.patchValue(this.form.controls.fields.length);
    this.form.controls.fields.push(newGroup);
    this.form.markAsDirty();
  }

  /**
   * Removes a field from the form. Removes value observers where needed.
   * @param index The index of the field to remove.
   */
  removeFieldAt(index: number): void {
    const control = this.form.controls.fields.controls[index];
    this._valueObserverService.removeObserverForControl(control);
    this.form.controls.fields.removeAt(index);
    this.form.controls.fields.markAsDirty();
    this.form.controls.fields.markAsTouched();
  }

  /**
   * Removes option from multi select field and unsubscribes from valueChanges for that option
   * @param fieldIndex The index of the field to remove the option from
   * @param optionIndex The index of the option to remove
   */
  removeOptionFromMultiSelectField(
    fieldIndex: number,
    optionIndex: number,
  ): void {
    const fieldFromGroup = this.form.controls.fields.controls[
      fieldIndex
    ] as FormGroup<CustomFormsMultiSelectFieldType<unknown>>;
    if (fieldFromGroup.controls.items?.length) {
      const optionFromGroup =
        fieldFromGroup.controls.items.controls[optionIndex];
      if (optionFromGroup) {
        this._valueObserverService.removeObserverForControl(optionFromGroup);
        fieldFromGroup.controls.items.removeAt(optionIndex);
      }
    }
  }

  /**
   * Adds a new option to a multi select field
   * @param index The index of the field to add the option to
   */
  addOptionToMultiSelectField(index: number): void {
    const formGroup: FormGroup<CustomFormsMultiSelectFieldType<unknown>> =
      this.form.controls.fields.controls[index];
    const newControl = this._formBuilderService.createMultiSelectItemFormGroup({
      sort: formGroup.controls.items?.length ?? 0,
      value: '',
      label: '',
      id: '',
      meta: null as any,
    });
    formGroup.controls.items?.push(newControl);
    formGroup.controls.items?.markAsDirty();
  }

  /**
   * Moves a field up or down in the form
   * @param previousIndex The index of the field to move
   * @param newIndex The index to move the field to
   */
  moveField(previousIndex: number, newIndex: number): void {
    const array = this.form.controls.fields.controls;
    moveInArray(array, previousIndex, newIndex);
    this.form.controls.fields.controls = array;
    for (let i = 0; i < array.length; i++) {
      array[i].controls.sort?.patchValue(i);
      array[i].markAsDirty();
    }
    this.form.controls.fields.patchValue(array.map((a) => a.value));
    this.form.controls.fields.markAsTouched();
  }

  /**
   * Moves an option up or down in a multi select field
   * @param fieldIndex The index of the field to move the option in
   * @param previousIndex The index of the option to move
   * @param newIndex The index to move the option to
   */
  moveMultiSelectOption(
    fieldIndex: number,
    previousIndex: number,
    newIndex: number,
  ): void {
    const formGroup = this.form.controls.fields.controls[
      fieldIndex
    ] as FormGroup<CustomFormsMultiSelectFieldType<unknown>>;
    const array = formGroup.controls.items?.controls;
    if (array) {
      moveInArray(array, previousIndex, newIndex);
      // eslint-disable-next-line
      formGroup.controls.items!.controls = array;
      for (let i = 0; i < array.length; i++) {
        array[i].controls.sort?.patchValue(i);
        array[i].markAsDirty();
      }
      formGroup.controls.items?.patchValue(array.map((a) => a.value));
      formGroup.controls.items?.markAsTouched();
    }
  }

  /**
   * Submits the form to the server. If the form is in edit mode, it will update the form. Otherwise, it will create a new form.
   * @param onSuccess A callback to run when the form is successfully submitted
   */
  submit(onSuccess?: (result: CustomForm) => void): void {
    // Wait for loading to complete before callback since the result stream is being replayed.
    this.loading$
      .pipe(
        filterTrue(),
        take(1),
        concatWith(this._submittedValid$.pipe(take(1))),
        concatWith(this.loading$.pipe(filterFalse(), take(1))),
        concatWith(this.result$.pipe(take(1), tap(onSuccess))),
        takeUntil(
          merge(
            this._destroy$,
            this._submissionError$,
            this.triedSubmittingInvalid$,
          ),
        ),
      )
      .subscribe();

    this._submitEvent$.next();
  }

  /**
   * Loads the form with the given id and pre-populates the form with the data. The form will be disabled until populated.
   * @param config
   */
  private _prePopulateForm(config?: CustomFormEditConfig): void {
    // Disable the form until it is populated
    if (config?.formId) {
      this.loadingForm$.next(true);
      this.form.disable();
      this._cdRef.detectChanges();
      this._dataSource
        .getFreshById(config.formId)
        .pipe(
          tap((r) => {
            for (let i = 0; i < r.fields.length; i++) {
              const field = r.fields[i];
              this.form.controls.fields.setControl(
                i,
                this._formBuilderService.createFormFieldFormGroup(
                  field.fieldType,
                  true,
                  field,
                ),
              );
            }
            // Remove any extra fields that might be present from previous interactions.
            for (
              let i = r.fields.length;
              i < this.form.controls.fields.length;
              i++
            ) {
              this.form.controls.fields.removeAt(i);
            }
            this.form.patchValue(r);
          }),
          map(() => this.config),
          switchMap((config) => ensureObservable(config?.institutions ?? null)),
          tap((institutions) => {
            if (institutions)
              this.form.controls.institutions.patchValue(institutions as any);
          }),
          tap(() => {
            this.form.enable();
            this.form.markAsPristine();
            this.loadingForm$.next(false);
          }),
        )
        .subscribe();
    } else if (config?.institutions) {
      ensureObservable(config.institutions)
        .pipe(take(1))
        .subscribe((institutions) => {
          // remove all field controls
          while (this.form.controls.fields.length) {
            this.form.controls.fields.removeAt(0);
          }
          this.form.reset({
            institutions: institutions,
            feature: this.feature,
          });
        });
    } else {
      this.form.reset({ feature: config?.feature });
    }
  }
}

function extractFormGroupValueToCustomForm(
  { value }: FormGroup<FormGroupModel>,
  formId?: string,
  feature?: CustomFormFeature,
): CustomForm {
  return CustomForm.deserialize({
    id: formId,
    ...value,
    feature: value.feature ?? feature,
    currentFormVersion: { fields: value.fields },
  });
}
