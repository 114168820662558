import {
  AfterViewInit,
  booleanAttribute,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {
  DocumentEditorContainerComponent,
  DocumentEditorContainerModule,
  PrintService,
  ToolbarItem,
  ToolbarService,
} from '@syncfusion/ej2-angular-documenteditor';
import { HttpClient } from '@angular/common/http';
import {
  BehaviorSubject,
  finalize,
  map,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';
import { CustomToolbarItemModel } from '@syncfusion/ej2-angular-pdfviewer';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AuthV2Service } from '@tremaze/shared/core/auth-v2';

@Component({
  selector: 'tremaze-document-editor',
  standalone: true,
  imports: [
    CommonModule,
    DocumentEditorContainerModule,
    MatProgressSpinnerModule,
  ],
  template: `
    <ejs-documenteditorcontainer
      serviceUrl="https://wordprocessor.tagea.app/api/documenteditor/"
      [enableToolbar]="true"
      [toolbarItems]="additionalToolbarItems"
      (toolbarClick)="onToolbarClick($event)"
      [currentUser]="(currentUserName$ | async) ?? ''"
      locale="de-DE"
      [documentEditorSettings]="{ printDevicePixelRatio: 5 }"
    ></ejs-documenteditorcontainer>
    @if (isLoading$ | async) {
      <div
        style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; background-color: rgba(255, 255, 255, 0.8); display: flex; justify-content: center; align-items: center;"
      >
        <mat-spinner></mat-spinner>
      </div>
    }
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
      }

      ejs-documenteditorcontainer {
        display: block;
        height: 100% !important;
        width: 100% !important;
      }
    `,
  ],
  providers: [ToolbarService, PrintService],
})
export class DocumentEditorComponent implements OnDestroy, AfterViewInit {
  readonly currentUserName$ = inject(AuthV2Service).authenticatedUser$.pipe(
    map((u) => u.firstName + ' ' + u.lastName),
  );

  get proxyFixedDocumentUrl() {
    if (!this.documentUrl) {
      return;
    }
    return this.documentUrl.replace(
      'http://localhost:4200/api',
      'https://api.dev.cloud.tagea.app',
    );
  }

  private _documentUrl?: string;

  get documentUrl(): string | undefined {
    return this._documentUrl;
  }

  @Input({ required: true })
  set documentUrl(value: string) {
    this._documentUrl = value;
    const proxyFixedDocumentUrl = this.proxyFixedDocumentUrl;
    if (proxyFixedDocumentUrl) {
      this.loadDocument(proxyFixedDocumentUrl);
    }
  }

  @Input({ required: true }) documentName = 'document.pdf';
  @Input() additionalToolbarItems: (CustomToolbarItemModel | ToolbarItem)[] =
    [];
  @Input({ transform: booleanAttribute }) canWrite = true;
  @Output() additionalToolbarItemClicked = new EventEmitter<string>();
  @Output()
  readonly contentChange = new EventEmitter<void>();

  @ViewChild(DocumentEditorContainerComponent, { static: true })
  documentEditor?: DocumentEditorContainerComponent;

  private _destroyed$ = new Subject<void>();
  readonly isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(private readonly _http: HttpClient) {}

  ngAfterViewInit() {
    if (this.documentEditor) {
      (this.documentEditor.contentChange as EventEmitter<unknown>).subscribe(
        () => {
          this.contentChange.emit();
        },
      );

      if(!this.canWrite) {
        this.documentEditor.enableToolbar = false;
        this.documentEditor.restrictEditing = true;
      }
    }
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
    this.isLoading$.complete();
  }

  public onToolbarClick(args: ClickEventArgs): void {
    this.additionalToolbarItemClicked.emit(args.item.id);
  }

  public print(): void {
    if (this.documentEditor) {
      this.documentEditor.documentEditor.print();
    }
  }

  private loadDocument(url: string) {
    this.isLoading$.next(true);
    this._http
      .get(url, {
        responseType: 'blob',
      })
      .pipe(
        switchMap((r) => {
          // send the file back to api to convert it to .sfdt format
          const data = new FormData();
          data.append('file', r as any);
          return this._http.post('/syncfusion/import/word', data, {
            responseType: 'text',
          });
        }),
        takeUntil(this._destroyed$),
        finalize(() => this.isLoading$.next(false)),
      )
      .subscribe((response) => {
        if (this.documentEditor) {
          this.documentEditor.documentEditor.open(response as any);
        }
      });
  }

  async save() {
    if (!this.documentEditor) {
      return;
    }
    const blob = await this.documentEditor.documentEditor.saveAsBlob('Docx');
    return new Blob([blob], { type: 'application/msword' });
  }
}
