<div class="folder-view-file-item__Head">
  <tremaze-icon [icon]="icon"></tremaze-icon>
  @if (isEditing && formControl) {
    <tremaze-folder-view-item-name-input
      class="folder-view-file-item__Name-Input"
      [formControl]="formControl"
      (cancel)="onCancel()"
      (submitText)="onSubmitText()"
      (lostFocus)="onLostFocusOnNameInput()"
    ></tremaze-folder-view-item-name-input>
  } @else {
    <figcaption class="folder-view-file-item__Head__Name">
      {{ name }}
    </figcaption>
    <button
      mat-icon-button
      class="small-icon-button"
      [matMenuTriggerFor]="menu"
      (click)="$event.stopPropagation()"
      [disabled]="disabled"
    >
      <tremaze-icon
        icon="lnr-ellipsis"
        style="transform: rotate(90deg)"
      ></tremaze-icon>
    </button>
  }
</div>
@if (file) {
  <tremaze-single-file-display
    class="folder-view-file-item__File-Preview"
    [file]="file"
    [canShowPreviewOverlay]="false"
    [draggable]="false"
  ></tremaze-single-file-display>
}
<mat-menu #menu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item (click)="showFilePreview.emit()">
      <span class="folder-view-file-item__Menu__Item__Content-Wrapper">
        <tremaze-icon
          class="folder-view-file-item__Menu__Item__Icon"
          icon="lnr-eye"
        ></tremaze-icon>
        <span>Datei ansehen</span>
      </span>
    </button>

    @if (file?.absolutePath) {
      <button mat-menu-item (click)="navigateToPath.emit()">
        <span class="folder-view-file-item__Menu__Item__Content-Wrapper">
          <tremaze-icon
            class="folder-view-file-item__Menu__Item__Icon"
            icon="lnr-exit-right"
          ></tremaze-icon>
          <span>Gehe zu Ordner</span>
        </span>
      </button>
    }

    <button mat-menu-item (click)="copyFileLink.emit()">
      <span class="folder-view-file-item__Menu__Item__Content-Wrapper">
        <tremaze-icon
          class="folder-view-file-item__Menu__Item__Icon"
          icon="lnr-share"
        ></tremaze-icon>
        <span>Öffentlichen Link kopieren</span>
      </span>
    </button>

    <button mat-menu-item (click)="downloadFile.emit()">
      <span class="folder-view-file-item__Menu__Item__Content-Wrapper">
        <tremaze-icon
          class="folder-view-file-item__Menu__Item__Icon"
          icon="lnr-download2"
        ></tremaze-icon>
        <span>Herunterladen</span>
      </span>
    </button>

    @if (!canWrite$ || (canWrite$ | async)) {
      <button mat-menu-item (click)="duplicateFile.emit()">
        <span class="folder-view-file-item__Menu__Item__Content-Wrapper">
          <tremaze-icon
            class="folder-view-file-item__Menu__Item__Icon"
            icon="lnr-copy"
          ></tremaze-icon>
          <span>Datei duplizieren</span>
        </span>
      </button>

      <button mat-menu-item (click)="onClickEditName()">
        <span class="folder-view-file-item__Menu__Item__Content-Wrapper">
          <tremaze-icon
            class="folder-view-file-item__Menu__Item__Icon"
            icon="lnr-pencil2"
          ></tremaze-icon>
          <span>Datei umbenennen</span>
        </span>
      </button>
    }

    @if (!canDelete$ || (canDelete$ | async)) {
      <button mat-menu-item (click)="deleteFile.emit()">
        <span
          class="folder-view-file-item__Menu__Item__Content-Wrapper"
          style="color: red"
        >
          <tremaze-icon
            class="folder-view-file-item__Menu__Item__Icon"
            icon="lnr-trash2"
          ></tremaze-icon>
          <span>Löschen</span>
        </span>
      </button>
    }
  </ng-template>
</mat-menu>
